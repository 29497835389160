@import "/styles/rc-pallet-2021";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/_breakpoints";
@import "bootstrap/scss/mixins/_grid";
@import "bootstrap/scss/mixins/_deprecate";

@import "/styles/ui/rc-ui-sidebar";


// 4.5 -> 5 upgrade ---------------------------
@mixin make-container($gutter: $grid-gutter-width) {
  width: 100%;
  padding-right: $gutter / 2;
  padding-left: $gutter / 2;
  margin-right: auto;
  margin-left: auto;
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
  @include deprecate("The `make-container-max-widths` mixin", "v4.5.2", "v5");
}

.form-group {
  margin-bottom: 1em;
}

.btn-block {
  width: 100%;
}
// END 4.5 -> 5 upgrade ---------------------------

.content,
.modal-content {
  font-family: $rc-font-family;
  font-size: $rc-text-font-size;
  color: $rc-text-primary-color;
}

em {
  font-weight: 700;
  font-style: normal;
}

// --- defaults
h1 {
  font-style: normal;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 15px;
  color: $rc-header-color;
  font-family: $rc-header-font-family;
  font-size: 2em;
  margin-bottom: 4px;
  text-transform: uppercase;
}

h2 {
  color: $rc-header-color;
  font-family: $rc-header-font-family;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  // font-family: $rc-header-font-family;
  // font-size: .75em;
  margin-bottom: 8px;
  text-transform: uppercase;

  a {
    font-family: $rc-header-font-family;
    font-size: 0.75em;
  }
}

h3 {
  color: $rc-text-color-primary;
  font-family: $rc-header-font-family;
  font-size: $rc-text-font-size;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.table {
  font-family: $rc-font-family;
  font-size: $rc-text-font-size;
  color: $rc-text-primary-color;
}
.table-bordered {
  border: thin solid $rc-box-border-color;
}
.thead-light th {
  font-size: $rc-text-font-size;
  color: $rc-header-color;
  background-color: $rc-box-background-color;
}

.container {
  @include make-container-max-widths;

  @include media-breakpoint-down(md) {
    padding-right: 15px;
  }
}

.ngb-dp-body {
  z-index: 10001 !important;
}

.page-container {
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}

.alert-success {
  color: white;
  background-color: rgb(40, 167, 69);
  border-color: rgb(40, 167, 69);
}

.alert-info {
  color: white;
  background-color: $rc-text-color-primary;
  border-color: $rc-text-color-primary;
}

.alert-danger,
.alert-warning {
  color: white;
  background-color: $rc-danger-color;
  border-color: $rc-danger-color;
}

.alert {
  color: map-get(map-get($tints, white), 100);
  font-weight: bold;
  padding: $rc-cta-button-padding;
}

.ng-busy-default-sign {
  border: 1px solid $rc-elem-border-color !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  top: 15px;
}

.ng-busy-backdrop {
  z-index: 100;
}

// ----- icons
.icon {
  &__delete {
    color: $rc-danger-color;
  }
  &__clear {
    // clean current field,  group
    color: $rc-text-primary-color;
    border-color: $rc-text-primary-color !important;
  }
}

.page {
  padding-left: 30px !important;

  @include make-container();
  @include media-breakpoint-down(md) {
    padding: 0 15px !important;
  }
}
// -------------------------------------------------------- END page

// ------------------------------------------------------------------------------------------- rc-library
.rc-library {
  .card {
    border: 2px solid $rc-elem-border-color;

    .card-wrapper {
      @include media-breakpoint-down(md) {
        display: flex;
        flex: 1 1 auto;
      }

      .card-img-wrapper {
        .card-img {
          position: relative;

          .card-img-top {
            border-bottom: 0;

            @include media-breakpoint-down(md) {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              padding: 2px;
            }
          }

          .card-overlay {
            background-color: map-get(map-get($tints, white), 80);
            border-top-left-radius: $rc-elem-border-radius;
            border-top-right-radius: $rc-elem-border-radius;
            color: $rc-text-color-primary;
            font-size: 19px;
            font-weight: 400;
            padding: 15px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            text-align: right;
            text-transform: uppercase;

            @include media-breakpoint-down(md) {
              font-size: 14px;
            }
          }
        }
      }

      .card-body {
        border: 0;
        font-size: $rc-text-font-size;

        @include media-breakpoint-down(md) {
          width: 20vh;
        }

        .card-title {
          display: block;
          font-size: $rc-text-font-size;
          margin-bottom: 10x;
          text-transform: $rc-cta-link-transform;
        }

        .btn-link {
          color: $rc-accent-color;
          padding: 0 !important;
        }
      }
    }

    .card-footer {
      background-color: transparent;
      padding: 0 !important;
    }
  }
}
// ------------------------------------------------------------------------------------------- END rc-library


a,
.span-link {
  color: $rc-accent-color;
  font-size: $rc-text-font-size;
  // font-weight: $rc-cta-link-padding;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

p {
  font-size: $rc-text-font-size;
}

h4 {
  color: $rc-text-primary-color;
  font-size: $rc-header-font-size;
  font-weight: $rc-header-weight;
  text-transform: inherit;
}

hr {
  background-color: $rc-elem-selected-color;
  border-color: $rc-hr-color;

  &.light {
    border-color: $rc-hr-lighten-color !important;
  }
}

hr {
  background-color: $rc-elem-selected-color;
  border-color: $rc-elem-border-color;
}

.prefix.input-group-text,
.suffix.input-group-text,
.prefix.btn {
  background-color: $rc-elem-selected-color;
  border-color: $rc-elem-border-color;
  padding: 0.375rem 0.75rem !important;
}

.has-danger {
  label.control-label-static {
    color: $rc-danger-color !important;
  }

  .form-control {
    border-color: $rc-danger-color !important;

    &::placeholder {
      color: $rc-danger-color !important;
    }
  }

  .form-control-feedback {
    color: $rc-danger-color !important;
    font-size: $rc-text-input-hint-font-size;
  }

  .prefix.input-group-text,
  .suffix.input-group-text,
  .prefix.btn {
    border-color: $rc-danger-color !important;
    color: $rc-danger-color !important;
  }
}

// buttons at bottom of page,  back, next, save, etc.
//     <div class="col-12 text-end page__actions">
// add buttins and what not to the div.
.page__actions {
  .btn {
    vertical-align: top; // without this the buttons did not align..  the 2,3,4... were slightly lower. pita
    margin-left: 0.5em; // add a little space between buttons

    .svg-inline--fa {
      padding-left: 5px;
      padding-right: 5px;
    }

    @include media-breakpoint-down(lg) {
      // font-size: 14px;
      width: 100%;
      margin-bottom: 5px;
      margin-left: 0px;
    }
  }
}

// aok up/down - not sure if this is used because the component also defines.
.btn-group-vertical {
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;

  > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 1px 12px;
    margin-left: -1px;
    position: relative;
    border-radius: 0;

    &:first-child {
      border-top-right-radius: 4px !important;
    }

    &:last-child {
      margin-top: -2px;
      border-bottom-right-radius: 4px !important;
    }

    i {
      line-height: 24px;
    }
  }
}

label {
  &.control-label,
  &.control-label-static {
    color: $rc-text-primary-color;
    font-size: $rc-text-font-size;
    font-weight: 700;
    margin-bottom: 2px;
    opacity: 1;
    pointer-events: none;
    position: relative;
    transform: none;
    transform-origin: inherit;
    transition: none;
    display: block;
  }
}

// unable to find - 2021-05-18
// div.description {
//   > a {
//     display: inline-block;
//   }
// }

// 2021-06-01 - overview not used any more...
// rc-overview
// ul.list {
//   list-style: none;
//   margin: 0;
//   padding: 0;

//   li {
//     margin: 0 0 10px;
//     padding: 0;

//     &:last-of-type {
//       margin-bottom: 0;
//     }

//     > i {
//       color: $rc-accent-color;
//       float: left;
//       font-size: 18px;
//       margin-right: 10px;
//       line-height: 24px;
//     }

//     > span {
//       display: block;
//       overflow: hidden;
//     }
//   }
// }

// ------------------------------------------------------------------------------------------- nouislider: used on rc-img-cropper
.noUi-target {
  border: 0;
  height: 6px;
}

.noUi-background {
  height: 16px;
}

.noUi-base {
  .noUi-connect {
    background-color: $rc-accent-color;
  }

  .noUi-handle {
    background-color: $rc-text-color-primary !important;
    border: none;
    border-radius: 50%;
    box-shadow: none;
    cursor: ew-resize;
    font-size: 15px;
    height: 24px;
    padding: 0;
    position: absolute;
    right: -13px !important;
    top: -9px;
    width: 24px;
    z-index: 2;
    outline: none;

    &::before {
      background: transparent;
      content: attr(data-value);
      display: table-cell;
      font-size: 18px;
      font-weight: 100;
      position: static;
      width: 100%;
      vertical-align: middle;
    }

    &::after {
      display: none;
    }
  }
}
// ------------------------------------------------------------------------------------------- END - nouislider: used on rc-img-cropper

.form-control {
  font-size: $rc-text-input-font-size;
  padding: $rc-text-input-padding;

  &:disabled,
  &[readonly] {
    border: 1px solid $rc-elem-border-color;
  }
}

// ------------------------------------------------------------------------- style-options
.style-options {
  @include make-row(0);

  .style-option {
    @include make-col-ready();
    @include media-breakpoint-down(md) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(4);
    }

    @include media-breakpoint-down(md) {
      margin: 0 0 15px;
    }

    .card {
      border: $rc-elem-border-width solid $rc-elem-border-color;
      margin: 0 15px 15px 0;

      @include media-breakpoint-down(md) {
        margin: 0;
      }
    }

    label {
      border: $rc-elem-border-width solid $rc-elem-border-color;
      border-radius: $rc-elem-border-radius;
      cursor: pointer;
      display: block;
      margin: 0 15px 0 0;
      padding: 15px;
      width: 100%;

      @include media-breakpoint-down(md) {
        margin: 0;
      }

      &.active {
        background-color: $rc-elem-selected-color;
      }

      &.tail {
        margin: 0;
      }

      input[type="radio"] {
        margin: 5px 5px 0 0;
        outline: none;
        vertical-align: middle;
      }

      .title {
        display: inline-block;
        margin-bottom: 5px;
        vertical-align: middle;
      }
    }
  }
}
// ------------------------------------------------------------------------- END style-options

// organizer page header h1 and a p
.page-header {
  margin-bottom: 2em;
}

// ------------------------------------------------------------------------------------------- nav-tabs
// Tabs used on organizer pages.  Probably should add a class to split out from any other nav tabs.
.nav-tabs {
  .nav-link {
    color: $rc-subnav-color;
    border-color: $rc-subnav-border-color;
    background-color: $rc-subnav-background-color;
    border-width: 1px;
    font-family: $rc-font-family;
    font-size: $rc-text-font-size;
    font-weight: $rc-subnav-font-weight;
    text-decoration: none;
    padding: 1rem 1.5rem;

    @include media-breakpoint-down(lg) {
      font-size: 14px;
      padding: 0.5rem 0.5rem;
    }

    &:hover,
    &:focus {
      border-color: $rc-subnav-color--active;
      color: $rc-subnav-color--active;
      text-decoration: if($link-hover-decoration == underline, none, null);
    }

    &.active {
      color: $rc-subnav-color--active;
      border-color: $rc-subnav-border-color--active;
      background-color: $rc-subnav-background-color--active;
    }
  }
}

.tab-content {
  padding: 1rem 0;
}
// ------------------------------------------------------------------------------------------- END nav-tabs

// ------------------------------------------------------------------------------------------- Buttons
a {
  &.btn {
    text-decoration: none !important;
  }
}
.accordion-button,
.btn {
  font-size: $rc-button-font-size;

  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;

  font-family: $rc-header-font-family;
  // font-size: $rc-text-font-size !important;
  // font-weight: $rc-cta-link-padding !important;
  padding: $rc-cta-button-padding;
  text-transform: $rc-cta-link-transform;

  background-color: white;
  color: $rc-button-color;
  border-color: $rc-accent-color;
  border-width: 2px;

  // &.btn-primary {
  //   background: $rc-button-color !important;
  //   border-color: $rc-button-border-color !important;
  //   color: $rc-button-text-color !important;

  //   &.purple {
  //     background-color: $rc-accent-color !important;
  //     color: $rc-button-light-text-color !important;
  //   }
  //}

  // &.btn-outline-primary {
  //   background-color: map-get(map-get($tints, white), 100) !important;
  //   border-width: 2px;

  //   &.purple {
  //     border-color: $rc-accent-color !important;
  //     color: $rc-accent-color !important;
  //   }
  // }

  &:focus {
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
    background-color: $rc-button-color !important;
    color: white !important;
    border-color: $rc-accent-color !important;
  }

  &.btn-selected {
    background: $rc-accent-color !important;
    border: none !important;
    color: map-get(map-get($tints, white), 100) !important;

    &:focus {
      box-shadow: 0 0 8px $rc-accent-shadow-color !important;
    }
  }

  &.btn-link {
    box-shadow: none;
    color: $rc-accent-color;
    outline: none;
    text-decoration: none;
    border: none;

    &:hover {
      text-decoration: none;
    }
  }
}

.rc-btn--noborder {
  border: none;
}
// --------------------------------------------------------------------------- END Buttons

.form-text {
  font-size: $rc-text-input-hint-font-size !important;

  &:not(.text-muted) {
    margin: 0 0 10px;
  }
}

.img-thumbnail {
  border-color: $rc-elem-border-color;
}

.highlight {
  background-color: $rc-text-highlight-color;
  border-radius: $border-radius;
  display: inline-block;
  padding: 1px 8px;
  line-height: 18px;
}

.dropdown-menu,
.ngb-dp-weekdays {
  border-color: $rc-elem-border-color !important;
}

//---------------------------------------------- aok-update
.aok-update {
  // TODO: Maybe combine the __up,__down into a common class instead of multiple,
  //       Maybe use :frist :last instead of up/down
  &__category {
    &__up {
      border-top-right-radius: 0.25rem;
      line-height: 24px;
    }
    &__down {
      border-bottom-right-radius: 0.25rem;
      line-height: 24px;
    }
  }

  &__aok {
    &__up {
      border-top-right-radius: 0.25rem;
      line-height: 24px;
    }
    &__down {
      border-bottom-right-radius: 0.25rem;
      line-height: 24px;
    }
  }

  &__actions {
    position: relative;

    &__delete_button {
      font-family: inherit;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

// ------------------------------rc-dashboard.overview cards
.rc-dashboard {
  .overview {
    // 3 column card at the top of the dashboard screen
    .card {
      border: none;
      padding: 7px;

      // .form-control {
      //   font-size: $rc-text-input-font-size;
      //   padding: 0.375rem 0.75rem;  // default padding,  smaller fields than rc defaults.

      //   &:disabled,
      //   &[readonly] {
      //     border: 1px solid $rc-elem-border-color;
      //   }
      // }
    }

    .card-body {
      background-color: $rc-white;
      // padding: 10px 20px 10px 20px;
      border-radius: 7px;
      border: 1px solid $rc-box-border-color;

      h5.card-title,
      label.card-title {
        font-size: $rc-text-font-size;
        color: $rc-text-primary-color;
        font-family: $rc-font-family;
        text-align: left;
      }

      .card-text,
      .card-title {
        margin-bottom: 2px;
      }
    }
  }
}

// ------------------------------------------------------------------------------------------- rc-checklist
.rc-checklist {
  &__primary-list {
    // top level list,  no list style bullets.  (ul)
    list-style-type: none;
    padding-left: 0px;
  }

  &__checked-list {
    // List that has checkboxes,  no bullets/numebring
    // probably best with ul
    list-style-type: none;
    padding-left: 0px;

    > li {
      position: relative;
      padding-left: 25px;

      &:not(:first-of-type) {
        padding-top: 10px;
      }
    }

    &__checkbox {
      // the checkbox that preceedes the li text
      // this margins stuff seems destin to fail
      // initial way to get checkboxes and li to proper align (wrapping).
      font-size: $rc-text-font-size;
      margin-left: -25px;
      margin-right: 7px;
      color: black;
    }
  }

  &--two-columns {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}
// ------------------------------------------------------------------------------------------- END rc-checklist

// ------------------------------------------------------------------------------------------- rc-accordion
.rc-accordion {
  h2:not(:first-of-type) {
    padding-top: 15px;
  }

  a {
    // we want the link text words all capitalized.
    text-transform: capitalize;
  }

  a.btn {
    // we want the link text words all capitalized.
    text-transform: uppercase;
  }

  ngb-accordion {
    .accordion-item {
      padding-top: 12px;
      border: none;
    }

    .accordion-header {


      .accordion-button {
        margin-bottom: 0;
        background-color: white;
        border-radius: 7px;
        border: thin solid $rc-subnav-border-color;
        padding: 1.0rem 1.5rem;

        color: $rc-subnav-color;

        // below causes button to be full height/width for click region
        text-align: left;
        width: 100%;
        height: 100%;
      }

      .svg-inline--fa {
        color: black;
      }
    }

    .accordion-body {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      border-width: 1px;
      border-color: $rc-subnav-border-color;
      border-style: none solid solid solid;
      border-radius: 7px;
    }

    .accordion-header:not(.collapsed) {


      .accordion-button:not(.collapsed) {
        border-color: $rc-subnav-border-color--active;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;

        color: $rc-subnav-color--active;
        background-color: $rc-subnav-background-color--active;
        box-shadow: none;

        // below causes button to be full height/width for click region
        text-align: left;
        width: 100%;
        height: 100%;
      }

      .accordion-body:not(.collapsed) {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
      }
    }
    .accordion-body:not(.collapsed) {
      border-color: $rc-subnav-border-color--active;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }

    // draws a 'hr' between accordion sections.
    // <ngb-panel cardClass="sectionstart">
    .sectionstart:before {
      content: " ";
      display: block;
      border-top: 1px solid $rc-hr-color;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
      margin-bottom: 12px;
    }
  }
}
// ------------------------------------------------------------------------------------------- END rc-accordion
