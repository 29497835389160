@import "/styles/rc-variables";
@import "/styles/rc-functions";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

// duplicated in DJ --------------------------------------------
// Primary Pallet
$rc-white: rgba(255, 255, 255, 1);
$rc-logo-blue-color: rgba(76, 198, 233, 1);
$rc-logo-green-color: rgba(132, 196, 65, 1);
$rc-logo-red-color: rgba(238, 51, 35, 1);
$rc-logo-purple-color: rgba(172, 73, 155, 1);

$rc-text-color-primary: rgb(61, 159, 186, 1); // todo this is a bad name.
$rc-text-color-emphasis: rgba(108, 163, 49, 1);

$rc-light-grey: rgba(245, 245, 245, 1);
$rc-grey: rgba(218, 218, 218, 1);

$rc-text-font-size: 18px;
$rc-text-large-font-size: 32px;
$rc-text-small-font-size: 16px;

$rc-text-primary-color: rgba(96, 96, 96, 1);

/* ---------------------------------------------------- Common Element Styles */
$rc-font-family: "Lato", sans-serif;

/* ---------------------------------------- h1,h2,h3,etc */
$rc-header-font-family: $rc-font-family;
$rc-header-color: rgba(0, 0, 0, 1);

$rc-dash-card-background-color: #f1f1f1;

$rc-title-font-weight: 700;
// duplicated in DJ -------------------------------------------- END

/* ------- misc */
$rc-header-height: 74px;
$rc-elem-border-radius: $border-radius;
$rc-elem-border-width: 1px;
$rc-elem-border-width-selected: 3px;

$rc-text-input-font-size: $rc-text-font-size;
$rc-text-input-hint-font-size: $rc-text-font-size;
$rc-text-input-padding: 12px 10px;
$rc-header-font-size: 24px;
$rc-header-weight: 400;
$rc-cta-link-transform: uppercase;
$rc-cta-link-padding: 700;
$rc-cta-button-padding: 12px 20px;

/* ---------------------------------------------------- Common Colors */
$rc-primary-color: $rc-logo-blue-color;
$rc-primary-lighten-color: rgba(83, 206, 238, 1); // TODO OLD
$rc-primary-darken-color: rgba(12, 177, 224, 1); // TODO OLD

$rc-accent-color: rgba(187, 0, 187, 1);
$rc-accent-lighten-color: rgba(220, 123, 250, 1); // TODO OLD

$rc-success-color: $rc-text-color-emphasis;
$rc-danger-color: $rc-logo-red-color;
$rc-warning-color: $rc-danger-color;

$rc-primary-shadow-color: tint($rc-primary-color, 50%);
$rc-accent-shadow-color: tint($rc-accent-color, 50%);

$rc-text-accent-color: rgba(125, 125, 125, 1);
$rc-text-secondary-color: rgba(112, 112, 112, 1);
$rc-text-highlight-color: $rc-warning-color;

$rc-elem-border-color: rgba(218, 218, 218, 1);
$rc-elem-border-color-selected: rgba(108, 163, 49, 1);
$rc-elem-border-lighten-color: rgba(237, 237, 237, 1);
$rc-elem-selected-color: rgba(246, 246, 246, 1);

$rc-button-color: $rc-accent-color;
$rc-button-font-size: $rc-text-font-size;
$rc-button-active-color: map-get(map-get($tints, grey), 30); // TODO OLD
$rc-button-hover-color: map-get(map-get($tints, grey), 40); // TODO OLD
$rc-button-border-color: $rc-accent-color; // TODO OLD
$rc-button-text-color: $rc-accent-color; // TODO OLD
$rc-button-light-text-color: map-get(map-get($tints, white), 100); // TODO OLD

$rc-hr-color: $rc-elem-border-color;
$rc-hr-lighten-color: $rc-elem-border-lighten-color;

$rc-breadcrumbs-background-color: $rc-elem-border-lighten-color;
$rc-action-background-color: rgba(34, 34, 34, 1);
$rc-shadow-color: map-get(map-get($tints, black), 10);

$rc-box-background-color: $rc-light-grey;
$rc-box-border-color: $rc-grey;

// ---------------------------------------------------- tabs/acorbion/subnav elements
$rc-subnav-color: $rc-text-primary-color;
$rc-subnav-font-weight: $rc-title-font-weight;
$rc-subnav-border-color: $rc-subnav-color;
$rc-subnav-background-color: $rc-white;
// --active
$rc-subnav-color--active: $rc-text-color-primary;
$rc-subnav-border-color--active: $rc-subnav-color--active;
$rc-subnav-background-color--active: $rc-white;

$rc-nav-color: $rc-text-primary-color;
$rc-nav-font-weight: $rc-title-font-weight;
$rc-nav-border-color: $rc-subnav-color;
$rc-nav-background-color: $rc-white;
// --active
$rc-nav-color--active: $rc-text-color-primary;
$rc-nav-border-color--active: $rc-subnav-color--active;
$rc-nav-background-color--active: $rc-white;
