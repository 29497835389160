@import "/styles/rc-pallet-2021";
@import "/styles/rc-variables";

$rc-sidebar-size: 300px;
$rc-sidebar-item-active-size: 10px;
$rc-sidebar-item-spacer-color: map-get(map-get($tints, white), 100);
$rc-sidebar-group-background-color: $rc-primary-darken-color;
$rc-sidebar-text-color: $rc-text-primary-color;
$rc-sidebar-height: calc(100vh - #{$rc-header-height});
$rc-sidebar-icon-width: 32px;
$rc-sidebar-plan-background-color: $rc-sidebar-group-background-color;

$rc-sidebar-icon-small-font-size: 18px;
$rc-sidebar-icon-font-size: $rc-text-font-size;
$rc-sidebar-font-size: $rc-text-font-size;
$rc-sidebar-font-weight: $rc-title-font-weight;

ng-sidebar-container {
  font-family: $rc-font-family;
  cursor: default;
  display: flex;
  min-height: $rc-sidebar-height;

  #navigation {
    padding-top: 0;
  }
  .content {
    position: relative;
  }

  .ng-sidebar__content {
    position: relative !important;
    width: 100%;
  }

  .ng-sidebar__backdrop {
    background-color: map-get(map-get($tints, white), 70);
    opacity: 1;
  }

  ng-sidebar {
    display: block;

    .ng-sidebar {
      border-right-width: thin;
      border-right-style: solid;
      border-right-color: $rc-elem-border-color;

      background-color: $rc-nav-background-color;
      overflow-x: hidden !important;
      position: absolute !important;
      z-index: 1000;

      @include media-breakpoint-down(lg) {
        position: fixed !important;
        top: $rc-header-height;
      }

      &.ng-sidebar--animate {
        transition: transform 0.1s cubic-bezier(0, 0, 0.3, 1);
      }

      // .icon {
      //   &.small {
      //     i.fa {
      //       display: none;
      //     }
      //   }
      // }

      &.ng-sidebar--opened {
        .list-group {
          min-width: $rc-sidebar-size;
        }

        .svg-inline--fa,
        .fa,
        .badge {
          margin-right: 0.75rem;
        }
        .badge-danger {
          background-color: $rc-danger-color;
        }
        .svg-inline--fa,
        .fa {
          margin-left: 0.75rem;
        }
        .badge {
          margin-left: 0.6rem;
        }
      }
      &.ng-sidebar--closed {
        .list-group {
          min-width: 40px;
        }

        .svg-inline--fa,
        .badge {
          margin-right: 0.75rem;
        }
        .svg-inline--fa {
          margin-left: 0.75rem;
        }
        .badge {
          margin-left: 0.6rem;
        }

        .list-group-item {
          // padding: 1rem 0.75rem;

          // &.active {
          //   &:hover {
          //     .icon {
          //       &.small {
          //         i {
          //           color: $rc-sidebar-text-color !important;
          //         }
          //       }
          //     }
          //   }

          //   .icon {
          //     &.small {
          //       i {
          //         color: $rc-sidebar-text-color !important;
          //       }
          //     }
          //   }
          // }

          .sidebar__text,
          .icon_after {
            display: none;
          }

          // .icon {
          //   width: auto !important;

          //   &.small {
          //     display: inline-block;
          //     margin-right: 0 !important;
          //     padding: 0;
          //     position: relative !important;
          //     right: auto !important;
          //     top: 0 !important;

          //     i {
          //       font-size: $rc-sidebar-icon-small-font-size;
          //       padding: 1rem 0;
          //       width: 50px;
          //     }

          //     i.fa {
          //       display: inline-block;
          //     }
          //   }
          // }

          // &:hover {
          //   .icon {
          //     color: $rc-sidebar-text-color !important;
          //   }
          // }
        }
      }

      .list-group {
        // min-width: $rc-sidebar-size;

        .list-group-item {
          max-width: $rc-sidebar-size;

          &:hover {
            color: $rc-nav-color--active;
          }

          &.active {
            color: $rc-nav-color--active;
          }

          background-color: transparent;
          border: 0;

          border-left-width: $rc-sidebar-item-active-size;
          border-left-color: transparent;
          border-left-style: none;
          color: $rc-sidebar-text-color;
          cursor: pointer;
          font-size: $rc-sidebar-font-size;
          font-weight: $rc-sidebar-font-weight;
          position: relative;
          outline: none;
          // letter-spacing: 0px;
          text-decoration: none;

          padding: 0.7rem 0.2rem;

          // text-transform: uppercase;

          // .icon {
          //   display: inline-block;
          //   text-align: center;
          //   width: $rc-sidebar-icon-width;

          // }

          // .text {
          //   display: inherit;

          //   & * {
          //     vertical-align: middle;
          //   }

          //   > span {
          //     padding: .45rem 0;
          //   }

          //   > .icon {
          //     padding: .33rem 0;
          //   }
          // }
        }

        // .item-group {
        //   background-color: $rc-sidebar-group-background-color;
        //   max-height: 0;
        //   overflow: hidden;
        //   transition: ease-in-out 75ms max-height;

        //   .list-group-item {
        //     .text {
        //       margin-left: $rc-sidebar-icon-width;
        //     }
        //   }

        //   &.opened {
        //     max-height: 500px;
        //     transition: ease-in-out 150ms max-height;
        //   }
        // }

        div.spacer {
          border-bottom: 1px solid $rc-sidebar-item-spacer-color;
          // margin: .55rem 0;
          width: 100%;
        }
      }
    }
  }
}
