$alert-bg-level: 0;
$alert-border-level: 0;
$alert-color-level: 10;

$container-max-widths: (
  sm: 100%,
  lg: 960px,
  xl: 1280px,
);

@import "bootstrap";
