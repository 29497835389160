@import "/styles/rc-bootstrap";
@import "assets/nouislider.min.css";
@import "/styles/rc-pallet-2021";

@import "ngx-toastr/toastr";

// TODO: Font Awwesome used to provide this..
//       Maybe we should replace? 2021-07-09 (Happy BDay Miller).
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

// @import '/styles/rc-pallet';

.org-container {
  @import "/styles/ui/rc-ui-org";
}

// donor website,  currently used on org preview,  longer term import this and share with the actual donor website.
.donor_website {
  &__giving_back_list {
    &__completed_icon {
      color: $rc-logo-green-color;
      font-size: 30px;
    }
  }
}

// --------------------------------------------------------------------------- ngb-date-picker
.ngb-dp-day {
  .bg-primary {
    background-color: $rc-button-color !important;
  }
}

.ngb-dp-navigation-chevron {
  box-shadow: none;
  color: $rc-accent-color;
  outline: none;
  text-decoration: none;
  border: none;

  &:hover {
    text-decoration: none;
  }
}
// --------------------------------------------------------------------------- END ngb-data-picker

// ----------------------------------- guided tour style
$tour-step-color: white;
$tour-text-color: $rc-text-primary-color;
$tour-back-button-color: $rc-text-primary-color;
$tour-skip-link-color: $rc-text-primary-color;
$tour-next-button-color: $rc-button-color;
$tour-next-button-hover: $rc-button-color;
@import "ngx-guided-tour/scss/guided-tour-base-theme.scss";
ngx-guided-tour {
  .tour-step {
    font-family: $rc-font-family;
  }
  .tour-block {
    border-radius: 5px;
  }
}
// ----------------------------------- END guided tour style

.modal,
.modal-backdrop {
  z-index: 10000 !important;

  &.scrollable {
    display: block !important; /* I added this to see the modal, you don't need this */

    .modal-dialog {
      overflow-y: initial !important;
    }

    .modal-body {
      overflow-y: auto;
    }
  }
}

// --------------------------------------------------------- NGB Modal
.modal-content {
  .modal-header {
    text-align: center;

    h4 {
      margin: 0 auto;
    }
  }

  .modal-body {
    text-align: center;
  }

  .modal-footer {
    // org seems to like this for modal actions.
    text-align: center;
  }
}

.modal__close-btn {
  font-size: $rc-button-font-size;
  background: white;
  color: $rc-button-color;
  border: none;
  float: right;

  &:focus {
    outline: none;
  }
}
// --------------------------------------------------------- end NGB Modal

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// ------------ safari iPhone seems to want to mark
// the footer phone number different.
a[href^="tel"] {
  text-decoration: inherit;
  color: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

// -------------------------------- Clean up select specifically on Safari #246
// https://clicknathan.com/web-design/styling-select-elements-crossbrowser/
// I also added .select-container to the rc-select-field-component.html div
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.5em;
  background: #efefef;
  border: none;
  border-radius: 3px;
  padding: 1em 2em 1em 1em;
  font-size: 1em;
}

.select-container {
  position: relative;
  display: inline;
}

.select-container:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
  margin-top: 1em;
}

.select-container:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 0.3em;
  right: 0.75em;
  border-top: 8px solid black;
  opacity: 0.5;
}
// ------------------------------------------------------ end select field 'fix'


// -------- ng busy, i could not get the import to work

//@import "ng-busy/src/style/busy.css";
.ng-busy {
  z-index: 1002;
}
.ng-busy,
.ng-busy > *,
.ng-busy > ng-component > * {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.ng-busy-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  background: #fff;
  opacity: 0.7;
}
.ng-busy-default-wrapper {
  text-align: center;
}
.ng-busy-default-sign {
  position: relative;
  display: inline-block;
  z-index: 1003;
  padding: 12px 14px;
  border: 1px solid #d8d8d8;
  border-top: 0;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  background: #f8f8f8;
  color: #333;
}
.ng-busy-default-text {
  display: inline-block;
  margin-left: 6px;
  max-width: 400px;
  font-size: 14px;
  text-align: left;
}
.ng-busy-default-spinner {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
}
.ng-busy-default-spinner div {
  position: absolute;
  left: 44.5%;
  top: 37%;
  width: 10%;
  height: 26%;
  background: #666;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: busy-spinner-anim 1s linear infinite;
}
.ng-busy-default-spinner .bar1 {
  transform: rotate(0deg) translate(0, -142%);
  animation-delay: -1s;
}
.ng-busy-default-spinner .bar2 {
  transform: rotate(30deg) translate(0, -142%);
  animation-delay: -0.91666667s;
}
.ng-busy-default-spinner .bar3 {
  transform: rotate(60deg) translate(0, -142%);
  animation-delay: -0.83333333s;
}
.ng-busy-default-spinner .bar4 {
  transform: rotate(90deg) translate(0, -142%);
  animation-delay: -0.75s;
}
.ng-busy-default-spinner .bar5 {
  transform: rotate(120deg) translate(0, -142%);
  animation-delay: -0.66666667s;
}
.ng-busy-default-spinner .bar6 {
  transform: rotate(150deg) translate(0, -142%);
  animation-delay: -0.58333333s;
}
.ng-busy-default-spinner .bar7 {
  transform: rotate(180deg) translate(0, -142%);
  animation-delay: -0.5s;
}
.ng-busy-default-spinner .bar8 {
  transform: rotate(210deg) translate(0, -142%);
  animation-delay: -0.41666667s;
}
.ng-busy-default-spinner .bar9 {
  transform: rotate(240deg) translate(0, -142%);
  animation-delay: -0.33333333s;
}
.ng-busy-default-spinner .bar10 {
  transform: rotate(270deg) translate(0, -142%);
  animation-delay: -0.25s;
}
.ng-busy-default-spinner .bar11 {
  transform: rotate(300deg) translate(0, -142%);
  animation-delay: -0.16666667s;
}
.ng-busy-default-spinner .bar12 {
  transform: rotate(330deg) translate(0, -142%);
  animation-delay: -0.08333333s;
}
@keyframes busy-spinner-anim {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
