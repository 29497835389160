$rc-par-max-width: 980px;

/* ---------------------------------------------------- Common Helpers */
$opacity: (
  100: 1,
  90: 0.9,
  80: 0.8,
  70: 0.7,
  60: 0.6,
  50: 0.5,
  40: 0.4,
  30: 0.3,
  20: 0.2,
  10: 0.1,
  5: 0.05,
  4: 0.04,
  3: 0.03,
  2: 0.02,
  1: 0.01,
);

// Example: background-color: map-get(map-get($tints, black), 40) --> background-color: rgba(0, 0, 0, 0.4);
$tints: (
  black: (
    100: rgba(0, 0, 0, map-get($opacity, 100)),
    90: rgba(0, 0, 0, map-get($opacity, 90)),
    80: rgba(0, 0, 0, map-get($opacity, 80)),
    70: rgba(0, 0, 0, map-get($opacity, 70)),
    60: rgba(0, 0, 0, map-get($opacity, 60)),
    50: rgba(0, 0, 0, map-get($opacity, 50)),
    40: rgba(0, 0, 0, map-get($opacity, 40)),
    30: rgba(0, 0, 0, map-get($opacity, 30)),
    20: rgba(0, 0, 0, map-get($opacity, 20)),
    10: rgba(0, 0, 0, map-get($opacity, 10)),
    5: rgba(0, 0, 0, map-get($opacity, 5)),
    4: rgba(0, 0, 0, map-get($opacity, 4)),
    3: rgba(0, 0, 0, map-get($opacity, 3)),
    2: rgba(0, 0, 0, map-get($opacity, 2)),
    1: rgba(0, 0, 0, map-get($opacity, 1)),
  ),
  white: (
    100: rgba(255, 255, 255, map-get($opacity, 100)),
    90: rgba(255, 255, 255, map-get($opacity, 90)),
    80: rgba(255, 255, 255, map-get($opacity, 80)),
    70: rgba(255, 255, 255, map-get($opacity, 70)),
    60: rgba(255, 255, 255, map-get($opacity, 60)),
    50: rgba(255, 255, 255, map-get($opacity, 50)),
    40: rgba(255, 255, 255, map-get($opacity, 40)),
    30: rgba(255, 255, 255, map-get($opacity, 30)),
    20: rgba(255, 255, 255, map-get($opacity, 20)),
    10: rgba(255, 255, 255, map-get($opacity, 10)),
    5: rgba(255, 255, 255, map-get($opacity, 5)),
    4: rgba(255, 255, 255, map-get($opacity, 4)),
    3: rgba(255, 255, 255, map-get($opacity, 3)),
    2: rgba(255, 255, 255, map-get($opacity, 2)),
    1: rgba(255, 255, 255, map-get($opacity, 1)),
  ),
  grey: (
    100: rgba(223, 223, 223, map-get($opacity, 100)),
    90: rgba(223, 223, 223, map-get($opacity, 90)),
    80: rgba(223, 223, 223, map-get($opacity, 80)),
    70: rgba(223, 223, 223, map-get($opacity, 70)),
    60: rgba(223, 223, 223, map-get($opacity, 60)),
    50: rgba(223, 223, 223, map-get($opacity, 50)),
    40: rgba(223, 223, 223, map-get($opacity, 40)),
    30: rgba(223, 223, 223, map-get($opacity, 30)),
    20: rgba(223, 223, 223, map-get($opacity, 20)),
    10: rgba(223, 223, 223, map-get($opacity, 10)),
    5: rgba(223, 223, 223, map-get($opacity, 5)),
    4: rgba(223, 223, 223, map-get($opacity, 4)),
    3: rgba(223, 223, 223, map-get($opacity, 3)),
    2: rgba(223, 223, 223, map-get($opacity, 2)),
    1: rgba(223, 223, 223, map-get($opacity, 1)),
  ),
);
